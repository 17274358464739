import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import './ResetPassword.css';
import '../utilities.css';

const ResetPassword: React.FC = () => {
  const [isValidToken, setIsValidToken] = useState<boolean | null>(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await fetch(`/api/auth/validate-reset-token/${token}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        if (response.ok) {
          const data = await response.json();
          setIsValidToken(true);
          setUserEmail(data.email);
        } else {
          setIsValidToken(false);
        }
      } catch (err) {
        console.error('Error validating token:', err);
        setIsValidToken(false);
      }
    };

    validateToken();
  }, [token]);

  useEffect(() => {
    const errors: string[] = [];
    setIsLengthValid(password.length >= 8);
    if (!isLengthValid) errors.push('Password must be at least 8 characters long.');
    setHasUppercase(/[A-Z]/.test(password));
    if (!hasUppercase) errors.push('Password must contain at least one uppercase letter.');
    setHasLowercase(/[a-z]/.test(password));
    if (!hasLowercase) errors.push('Password must contain at least one lowercase letter.');
    setHasNumber(/\d/.test(password));
    if (!hasNumber) errors.push('Password must contain at least one number.');
    setPasswordsMatch(password === confirmPassword && password !== '');
    if (!passwordsMatch) errors.push('Passwords do not match.');
    setValidationErrors(errors);
  }, [password, confirmPassword, isLengthValid, hasUppercase, hasLowercase, hasNumber, passwordsMatch]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    if (validationErrors.length > 0) return;

    try {
      const response = await fetch(`/api/auth/reset-password/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        setSuccess(true);
        if (isAuthenticated) {
          signOut();
        }
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to reset password. Please try again or contact support if the issue persists.');
      }
    } catch (err) {
      console.error('Error resetting password:', err);
      setError('An unexpected error occurred. Please try again or contact support if the issue persists.');
    }
  };

  const handleConfirm = () => {
    if (success || !isValidToken) {
      navigate('/login');
    } else if (isAuthenticated) {
      navigate('/');
    }
  };

  const isFormValid = isLengthValid && hasUppercase && hasLowercase && hasNumber && passwordsMatch;

  if (isValidToken === null) {
    return <div className="reset-password-container">Loading...</div>;
  }

  if (isValidToken === false) {
    return (
      <div className="reset-password-container">
        <div className="sds-logo mb-4">
          <img src="/logo-wide.png" alt="South Denver Studio Logo" />
        </div>
        <h2 className="mb-2">Invalid Reset Link</h2>
        <p className="mb-2">This password reset link is invalid or has expired.</p>
        <p className="mb-4">You may restart the process by going to the Login page and requesting another password reset.</p>
        <div className="flex-center">
          <button onClick={handleConfirm} className="submit-button">Confirm</button>
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="reset-password-container">
        <div className="sds-logo mb-4">
          <img src="/logo-wide.png" alt="South Denver Studio Logo" />
        </div>
        <h2 className="mb-2">Password Reset Successful</h2>
        <p className="mb-4">Your password has been successfully updated. You can now log in using your new password.</p>
        <div className="flex-center">
          <button onClick={handleConfirm} className="submit-button">Confirm</button>
        </div>
      </div>
    );
  }

  return (
    <div className="reset-password-container">
      <div className="sds-logo mb-4">
        <img src="/logo-wide.png" alt="South Denver Studio Logo" />
      </div>
      <div className="reset-password-content">
        <h2 className="reset-password-header mb-2">Reset Password</h2>
        <p className="mb-4">Hello <strong>{userEmail}</strong>, you can now enter a new password.</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="password">New Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm New Password:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex-center">
            <button type="submit" className="submit-button" disabled={!isFormValid}>
              Reset Password
            </button>
          </div>
        </form>
        {error && <p className="error-message mt-2">{error}</p>}
        <ul className="password-rules mt-4">
          {validationErrors.map((error, index) => (
            <li key={index} className="text-error">{error}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ResetPassword;
