import React, { useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import './OpenHouse.css';
import '../utilities.css';

interface FormData {
  name: string;
  businessName: string;
  phone: string;
  email: string;
  comments: string;
  event: string;
}

interface ValidationErrors {
  name: string;
  email: string;
  phone: string;
}

const OpenHouse: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    businessName: '',
    phone: '',
    email: '',
    comments: '',
    event: new Date().toLocaleDateString(),
  });

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
    name: '',
    email: '',
    phone: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const validateField = (fieldName: string, value: string) => {
    let errorMessage = '';
    switch (fieldName) {
      case 'name':
        errorMessage = value.trim() === '' ? 'Name is required' : '';
        break;
      case 'email':
        errorMessage = !validateEmail(value) ? 'Please enter a valid email address' : '';
        break;
      case 'phone':
        errorMessage = !validatePhone(value) ? 'Please enter a valid 10-digit phone number' : '';
        break;
    }
    setValidationErrors(prev => ({ ...prev, [fieldName]: errorMessage }));
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  const validatePhone = (phone: string): boolean => {
    const digits = phone.replace(/\D/g, '');
    return digits.length === 10;
  };

  const isFormValid = (): boolean => {
    return Object.values(validationErrors).every(error => error === '');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isFormValid()) {
      alert('Please correct the errors in the form.');
      return;
    }
    try {
      await axios.post('/api/open-house', {
        ...formData,
        phone: formData.phone.replace(/\D/g, ''),
      });
      alert('Thank you for your interest! We will be in touch soon.');
      setFormData({
        name: '',
        businessName: '',
        phone: '',
        email: '',
        comments: '',
        event: formData.event,
      });
      navigate('/');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting the form. Please try again.');
    }
  };

  return (
    <div className="open-house-container">
      <div className="sds-logo mb-4">
        <img src="/logo-wide.png" alt="South Denver Studio Logo" />
      </div>
      <h1 className="open-house-header mb-4">Open House Event</h1>
      <div className="thank-you-message mb-4">
        <h2>Thank You for Attending Our Open House!</h2>
        <p>We sincerely appreciate your interest in our photo studio. Your presence made our event truly special. To stay connected and receive updates on future events and exclusive offers, please fill out the form below. We look forward to serving your photography needs!</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name: *</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            maxLength={50}
          />
          {validationErrors.name && <p className="error-message">{validationErrors.name}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="businessName">Business Name:</label>
          <input
            type="text"
            id="businessName"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            maxLength={100}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone: *</label>
          <InputMask
            mask="(999) 999-9999"
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="(xxx) xxx-xxxx"
            required
          />
          {validationErrors.phone && <p className="error-message">{validationErrors.phone}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email: *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            maxLength={100}
          />
          {validationErrors.email && <p className="error-message">{validationErrors.email}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="comments">Comments:</label>
          <textarea
            id="comments"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            maxLength={500}
            rows={4}
          />
        </div>
        <input type="hidden" name="event" value={formData.event} />
        <div className="flex-center">
          <button type="submit" disabled={!isFormValid()}>Submit</button>
        </div>
      </form>
    </div>
  );
};

export default OpenHouse;
