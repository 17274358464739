import React, { useState } from 'react';
import './Login.css';
import '../utilities.css';

interface ForgotPasswordDialogProps {
  onClose: () => void;
}

const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await fetch('/api/auth/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        if (response.status === 429) {
          setError('Too many requests. Please try again later.');
          return;
        }
        const errorText = await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      setMessage(data.message);
    } catch (err: any) {
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal" style={{ position: 'relative' }}>
        <span 
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            cursor: 'pointer',
            fontSize: '24px',
            color: '#61dafb'
          }}
        >
          &times;
        </span>
        <h2 className="modal-title">Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="reset-email">Email:</label>
            <input
              type="email"
              id="reset-email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={!!message}
            />
          </div>
          {!message && (
            <div className="flex-center mt-2">
              <button type="submit">Reset Password</button>
            </div>
          )}
        </form>
        {message && <p className="text-primary mb-2">{message}</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default ForgotPasswordDialog;
