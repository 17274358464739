import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Calendar.css';
import '../utilities.css';
import { Button } from "@chakra-ui/react";
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

interface Event {
  id: string;
  sanitizedName: string;
  start: {
    dateTime: string;
  };
  end: {
    dateTime: string;
  };
  isOwned: boolean;
  acuityId?: string;
}

interface DateObject {
  year: number;
  month: number;
  day: number;
}

const DURATIONS: (number | string)[] = [2, 4, 6, 'all-day'];
const OPENING_TIME = '08:00';
const CLOSING_TIME = '22:00';
const TIME_GRANULARITY = 30; // in minutes
const TIME_BUFFER = 60; // in minutes
const ALL_DAY_THRESHOLD = 8; // hours

const createDate = (year: number, month: number, day: number): DateObject => ({ year, month, day });

const formatDate = ({ year, month, day }: DateObject): string =>
  `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

const addMonths = (date: DateObject, months: number): DateObject => {
  let newMonth = date.month + months;
  let newYear = date.year;
  while (newMonth > 11) { newMonth -= 12; newYear += 1; }
  while (newMonth < 0) { newMonth += 12; newYear -= 1; }
  return createDate(newYear, newMonth, 1);
};

const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

const minutesToTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};

const toDenverTime = (dateTimeString: string): string => {
  const date = new Date(dateTimeString);
  return date.toLocaleTimeString('en-US', { 
    hour: 'numeric', 
    minute: '2-digit',
    timeZone: 'America/Denver'
  });
};

const toDenverDate = (dateTimeString: string): string => {
  const date = new Date(dateTimeString);
  const options = { timeZone: 'America/Denver', year: 'numeric', month: '2-digit', day: '2-digit' } as const;
  const denverDate = date.toLocaleDateString('en-CA', options); // 'en-CA' gives us the format 'YYYY-MM-DD'
  return denverDate;
};

const getEventDuration = (event: Event): number => {
  return (new Date(event.end.dateTime).getTime() - new Date(event.start.dateTime).getTime()) / (60 * 60 * 1000);
};

const isAllDayEvent = (event: Event): boolean => {
  return getEventDuration(event) >= ALL_DAY_THRESHOLD;
};

const Calendar: React.FC = () => {
  console.log('Calendar component rendering');
  const navigate = useNavigate();
  const authHeader = useAuthHeader();
  const today = new Date();
  const todayRef = useRef(today); // Use ref to avoid dependency issues
  const [currentDate, setCurrentDate] = useState<DateObject>(createDate(today.getFullYear(), today.getMonth(), 1));
  const [events, setEvents] = useState<Event[]>([]);
  const [modalType, setModalType] = useState<'booking' | 'noAvailability' | 'bookingConfirmation' | 'eventDetails' | 'rateLimit' | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const isMounted = useRef(true);
  const calendarGridRef = useRef<HTMLDivElement>(null);
  const sseInitializedRef = useRef(false);

  // Cleanup on unmount
  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();
    abortControllerRef.current = controller;
    return () => {
      isMounted.current = false;
      controller.abort();
      sseInitializedRef.current = false;
    };
  }, []);

  const fetchEvents = useCallback(async () => {
      console.log('fetchEvents called with authHeader:', authHeader);
      
      if (!authHeader) { 
          console.error('No authentication token found');
          navigate('/login');
          return;
      }

      const firstDay = new Date(currentDate.year, currentDate.month, 1);
      const lastDay = new Date(currentDate.year, currentDate.month + 1, 0);

      const startDate = new Date(firstDay);
      startDate.setDate(startDate.getDate() - startDate.getDay());

      const endDate = new Date(lastDay);
      endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));
      // Set the end time to the end of the day (23:59:59.999)
      endDate.setHours(23, 59, 59, 999);

      try {
          console.log('Fetching calendar events. isMounted:', isMounted.current);
          if (!isMounted.current) return;

          const response = await fetch(`/api/calendar/events?start=${startDate.toISOString()}&end=${endDate.toISOString()}`, {
              method: 'GET',
              headers: {
                  'Authorization': authHeader,
                  'Accept': 'application/json'
              }
          });

          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          console.log('Events fetched successfully:', data);
          
          if (isMounted.current) {
              setEvents(data);
              setModalType(null);
          }
      } catch (error) {
          if (!isMounted.current) return;

          console.error('Error fetching events:', error);
          if (error instanceof Error) {
              if (error.message.includes('401')) {
                  console.error('Authentication token expired or invalid');
                  navigate('/login');
              } else if (error.message.includes('429')) {
                  setModalType('rateLimit');
              } else {
                  console.error('Unexpected error fetching events:', error);
              }
          }
      }
  }, [currentDate, authHeader, navigate]);

  /* 
   * Set up EventSource connection to receive real-time updates via SSE
  */
  const setupEventSource = useCallback(async () => {
    console.log('setupEventSource called with authHeader:', authHeader);
    
    if (!authHeader) {
      console.log('No authHeader, skipping setupEventSource');
      return null; 
    }

    if (sseInitializedRef.current) {
      console.log('SSE already initialized, skipping');
      return null;
    }

    // Abort any existing fetch request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }

    // Create new abort controller
    const controller = new AbortController();
    abortControllerRef.current = controller;
    const { signal } = controller;

    console.log('Creating new EventSource connection');
    sseInitializedRef.current = true;

    let reader: ReadableStreamDefaultReader<Uint8Array> | null = null;
    let cleanup: (() => void) | null = null;

    try {
      const response = await fetch('/api/calendar/updates', {
        method: 'GET',
        headers: {
          'Authorization': authHeader,
          'Accept': 'text/event-stream',
          'Cache-Control': 'no-cache',
          'Connection': 'keep-alive'
        },
        signal
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      reader = response.body!.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      const processChunk = (chunk: string) => {
        const lines = (buffer + chunk).split('\n');
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(6));
              console.log('Received SSE data:', data);
              
              // Handle different types of SSE messages
              switch (data.type) {
                case 'update':
                  if (data.timestamp && isMounted.current) {
                    fetchEvents();
                  }
                  break;
                case 'ping':
                  console.log('Received ping from server');
                  break;
                case 'connected':
                  console.log('SSE connection established');
                  break;
                default:
                  console.log('Unknown SSE message type:', data.type);
              }
            } catch (e) {
              console.error('Error parsing SSE data:', e);
            }
          }
        }
      };

      const pump = async () => {
        while (true) {
          if (!isMounted.current) {
            console.log('Component unmounted, stopping SSE pump');
            break;
          }
          const { done, value } = await reader!.read();
          if (done) {
            console.log('SSE connection closed by server');
            break;
          }
          const chunk = decoder.decode(value);
          processChunk(chunk);
        }
      };

      pump().catch(error => {
        console.error('SSE pump error:', error);
        if (isMounted.current) {
          sseInitializedRef.current = false;
          setTimeout(() => {
            if (isMounted.current) {
              setupEventSource();
            }
          }, 5000);
        }
      });

      cleanup = () => {
        console.log('Cleaning up SSE connection');
        if (reader) {
          reader.cancel();
        }
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
          abortControllerRef.current = null;
        }
        sseInitializedRef.current = false;
      };

      return cleanup;
    } catch (error) {
      console.error('Error setting up EventSource:', error);
      if (isMounted.current) {
        sseInitializedRef.current = false;
        setTimeout(() => {
          if (isMounted.current) {
            setupEventSource();
          }
        }, 5000);
      }
      return null;
    }
  }, [authHeader, fetchEvents]);

  // Set up SSE connection when auth token changes
  useEffect(() => {
    console.log('SSE useEffect called with authHeader:', authHeader);
    if (!authHeader || !isMounted.current) return;
    
    let cleanup: (() => void) | null = null;
    setupEventSource().then(cleanupFn => {
      if (cleanupFn) {
        cleanup = cleanupFn;
      }
    });

    return () => {
      if (cleanup) cleanup();
    };
  }, [setupEventSource, authHeader]);

  // Fetch events when current date changes
  useEffect(() => {
    console.log('Date/Auth change effect - fetching events');
    if (authHeader) {
      fetchEvents();
    }
  }, [currentDate, fetchEvents, authHeader]);

  const changeMonth = (direction: number) => setCurrentDate(prev => addMonths(prev, direction));

  const isSlotAvailable = useCallback((date: string, startTime: string, duration: string | number): boolean => {
    const dayEvents = events.filter(event => toDenverDate(event.start.dateTime) === date);

    if (duration === 'all-day') return dayEvents.length === 0;
    if (dayEvents.some(isAllDayEvent)) return false;

    const newEventStart = new Date(`${date}T${startTime}:00Z`);
    const newEventEnd = new Date(newEventStart.getTime() + (typeof duration === 'number' ? duration : parseInt(duration)) * 60 * 60 * 1000);
    const closingTime = new Date(`${date}T${CLOSING_TIME}:00Z`);

    if (newEventEnd > closingTime) return false;

    const bufferedEventStart = new Date(newEventStart.getTime() - TIME_BUFFER * 60 * 1000);
    const bufferedEventEnd = new Date(newEventEnd.getTime() + TIME_BUFFER * 60 * 1000);

    const isConflict = dayEvents.some(event => {
      const eventStart = new Date(event.start.dateTime);
      const eventEnd = new Date(event.end.dateTime);

      return (bufferedEventStart < eventEnd && bufferedEventEnd > eventStart);
    });

    return !isConflict;
  }, [events]);

  const getAvailableStartTimes = useCallback((duration: string | number, date: string): string[] => {
    if (duration === 'all-day') return [];

    const openingMinutes = timeToMinutes(OPENING_TIME);
    const closingMinutes = timeToMinutes(CLOSING_TIME);

    return Array.from({ length: (closingMinutes - openingMinutes) / TIME_GRANULARITY + 1 }, (_, index) => {
      const minutes = openingMinutes + index * TIME_GRANULARITY;
      const time = minutesToTime(minutes);
      return isSlotAvailable(date, time, duration) ? time : null;
    }).filter((time): time is string => time !== null);
  }, [isSlotAvailable]);

  const handleDateClick = useCallback((clickedDate: DateObject) => {
    const selectedDate = formatDate(clickedDate);
    const hasAllDayEvent = events.some(event => 
      toDenverDate(event.start.dateTime) === selectedDate && isAllDayEvent(event)
    );

    if (hasAllDayEvent) {
      setModalType('noAvailability');
      return;
    }

    const anyDurationAvailable = DURATIONS.some(duration =>
      duration === 'all-day' ? isSlotAvailable(selectedDate, OPENING_TIME, duration) :
        getAvailableStartTimes(duration, selectedDate).length > 0
    );

    if (anyDurationAvailable) {
      setSelectedDate(selectedDate);
      setModalType('bookingConfirmation');
    } else {
      setModalType('noAvailability');
    }
  }, [events, isSlotAvailable, getAvailableStartTimes]);

  const handleOwnedEventClick = useCallback((event: Event) => {
    setSelectedEvent(event);
    setModalType('eventDetails');
  }, []);

  const renderCalendarDays = useCallback(() => {
    const { year, month } = currentDate;
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    const startDate = new Date(firstDay);
    startDate.setDate(startDate.getDate() - startDate.getDay());

    const endDate = new Date(lastDay);
    endDate.setDate(endDate.getDate() + (6 - endDate.getDay()));

    const days = [];
    let currentDay = new Date(startDate);

    while (currentDay <= endDate) {
      const dayDate = createDate(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate());
      const formattedDate = formatDate(dayDate);
      const isCurrentMonth = dayDate.month === month;
      const todayDate = todayRef.current;
      const isToday = formattedDate === formatDate(createDate(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate()));

      const dayEvents = events
        .filter(event => toDenverDate(event.start.dateTime) === formattedDate)
        .sort((a, b) => {
          if (isAllDayEvent(a)) return -1;
          if (isAllDayEvent(b)) return 1;
          return new Date(a.start.dateTime).getTime() - new Date(b.start.dateTime).getTime();
        });

      days.push(
        <div
          key={formattedDate}
          className={`calendar-day ${isCurrentMonth ? '' : 'outside-month'} ${isToday ? 'today' : ''}`}
          onClick={() => handleDateClick(dayDate)}
        >
          <div className="day-number">
            {dayDate.day}
          </div>
          <div className="day-events">
            {dayEvents.map(event => (
              <div
                key={event.id}
                className={`event ${event.isOwned ? 'owned' : ''} ${isAllDayEvent(event) ? 'all-day' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (event.isOwned) {
                    handleOwnedEventClick(event);
                  }
                }}
              >
                <div className="event-name">{event.sanitizedName}</div>
                <div className="event-details">
                  <span className="event-time">
                    {isAllDayEvent(event) ? 'All Day' : toDenverTime(event.start.dateTime)}
                  </span>
                  {!isAllDayEvent(event) && (
                    <span className="event-duration">
                      {getEventDuration(event)}h
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      );

      currentDay.setDate(currentDay.getDate() + 1);
    }

    return days;
  }, [currentDate, events, handleDateClick, handleOwnedEventClick]);

  // Update calendar grid display
  useEffect(() => {
    const calendarGrid = calendarGridRef.current;
    if (calendarGrid) {
      calendarGrid.style.display = 'grid';
    }
  }, []);

  const confirmBooking = useCallback(() => {
    navigate(`/booking?date=${selectedDate}`);
  }, [navigate, selectedDate]);

  const renderEventDetailsModal = () => {
    if (!selectedEvent) return null;

    const duration = getEventDuration(selectedEvent);
    const isAllDay = isAllDayEvent(selectedEvent);
    const eventDate = new Date(selectedEvent.start.dateTime);
    const startTime = isAllDay ? 'All Day' : toDenverTime(selectedEvent.start.dateTime);
    const endTime = isAllDay ? 'All Day' : toDenverTime(selectedEvent.end.dateTime);
    const isFutureEvent = eventDate > new Date();

    return (
      <Modal
        isOpen={modalType === 'eventDetails'}
        onClose={() => setModalType(null)}
        title={`Studio Rental - ${isAllDay ? 'All Day' : `${duration}h`}`}
      >
        <div>
          <p><strong>Date:</strong> {eventDate.toLocaleDateString('en-US', { timeZone: 'America/Denver' })}</p>
          <p><strong>Start Time:</strong> {startTime}</p>
          <p><strong>End Time:</strong> {endTime}</p>
          <p><strong>Acuity ID:</strong> {selectedEvent.acuityId}</p>
          {isFutureEvent && (
            <p>
              If you wish to edit or cancel your event, you can do so from the email that was sent when you created the appointment. 
              Look for an email from scheduling@acuityscheduling-mail.com with a subject containing "New Appoiontment: Studio Rental".
            </p>
          )}
        </div>
        <div className="modal-actions" style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setModalType(null)} colorScheme="blue">Ok</Button>
        </div>
      </Modal>
    );
  };

  const Modal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
  }> = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal" onClick={e => e.stopPropagation()}>
          <h2 className="modal-title">{title}</h2>
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className="calendar-container">
      <div className="sds-logo">
        <img src="/logo-wide.png" alt="South Denver Studio Logo" />
      </div>
      <div className="calendar-header">
        <Button onClick={() => changeMonth(-1)} size="sm">&lt; Prev</Button>
        <h2>
          {new Date(currentDate.year, currentDate.month).toLocaleString('default', { month: 'long', year: 'numeric' })}
        </h2>
        <Button onClick={() => changeMonth(1)} size="sm">Next &gt;</Button>
      </div>
      <div className="calendar-grid" ref={calendarGridRef}>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="calendar-day-header">{day}</div>
        ))}
        {renderCalendarDays()}
      </div>

      <Modal
        isOpen={modalType === 'noAvailability'}
        onClose={() => setModalType(null)}
        title="No Availability"
      >
        <p>Sorry, there are no available time slots for the selected date.</p>
        <div className="flex-center">
          <Button onClick={() => setModalType(null)} colorScheme="blue">Ok</Button>
        </div>
      </Modal>

      <Modal
        isOpen={modalType === 'bookingConfirmation'}
        onClose={() => setModalType(null)}
        title=""
      >
        <p>Do you want to proceed to the booking page?</p>
        <div className="flex-center">
          <Button onClick={() => setModalType(null)} colorScheme="gray" style={{ marginRight: '10px' }}>No</Button>
          <Button onClick={confirmBooking} colorScheme="blue">Yes</Button>
        </div>
      </Modal>

      <Modal
        isOpen={modalType === 'rateLimit'}
        onClose={() => setModalType(null)}
        title="Too Many Requests"
      >
        <p className="error-message mb-2">
          Can't sync the calendar events.<br></br>Please wait before trying again.
        </p>
        <div className="flex-center">
          <Button onClick={() => setModalType(null)} colorScheme="blue">Ok</Button>
        </div>
      </Modal>

      {renderEventDetailsModal()}
    </div>
  );
};

export default Calendar;
