import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import AuthProvider from 'react-auth-kit/AuthProvider';
import createStore from 'react-auth-kit/createStore';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import './App.css';
import Home from './components/Home';
import Login from './components/Login';
import Booking from './components/Booking';
import Calendar from './components/Calendar';
import OpenHouse from './components/OpenHouse';
import RequireAuth from './components/RequireAuth';
import ResetPassword from './components/ResetPassword';
import UserProfile from './components/UserProfile';
import EmailVerification from './components/EmailVerification';
import Inquiries from './components/Inquiries';

interface NavigationProps {
  isLoggedIn: boolean;
  handleLogout: () => void;
}

const Navigation: React.FC<NavigationProps> = ({ isLoggedIn, handleLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getActiveTab = () => {
    const path = location.pathname;
    if (path === '/') return 'home';
    if (path === '/calendar') return 'calendar';
    if (path === '/booking') return 'booking';
    if (path === '/login') return 'login';
    if (path === '/profile') return 'profile';
    if (path === '/open-house') return 'open-house';
    return 'home';
  };

  return (
    <nav>
      <button 
        className={`App-link ${getActiveTab() === 'home' ? 'active' : ''}`} 
        onClick={() => navigate('/')}
      >
        Home
      </button>
      {isLoggedIn ? (
        <>
          <button 
            className={`App-link ${getActiveTab() === 'calendar' ? 'active' : ''}`} 
            onClick={() => navigate('/calendar')}
          >
            Calendar
          </button>
          <button 
            className={`App-link ${getActiveTab() === 'booking' ? 'active' : ''}`} 
            onClick={() => navigate('/booking')}
          >
            Booking
          </button>
          <button 
            className={`App-link ${getActiveTab() === 'profile' ? 'active' : ''}`} 
            onClick={() => navigate('/profile')}
          >
            Profile
          </button>
          <button 
            className="App-link" 
            onClick={handleLogout}
          >
            Logout
          </button>
        </>
      ) : (
        <button 
          className={`App-link ${getActiveTab() === 'login' ? 'active' : ''}`} 
          onClick={() => navigate('/login')}
        >
          Login
        </button>
      )}
    </nav>
  );
};

const AppContent: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate('/');
  };

  return (
    <div className="App">
      <header className="App-header">
        <Navigation isLoggedIn={isAuthenticated} handleLogout={handleLogout} />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/open-house" element={<OpenHouse />} />
            <Route path="/calendar" element={
              <RequireAuth loginPath="/login">
                <Calendar />
              </RequireAuth>
            } />
            <Route path="/booking" element={
              <RequireAuth loginPath="/login">
                <Booking />
              </RequireAuth>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={
              <RequireAuth loginPath="/login">
                <UserProfile />
              </RequireAuth>
            } />
            <Route path="/verify-email/:token" element={<EmailVerification />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/inquiries" element={<Inquiries />} />
            <Route path="*" element={
              <div>
                <h1>Welcome to South Denver Photography Studio</h1>
                <p>We're sorry, but something's wrong: [404 Not Found]</p>
              </div>
            } />
          </Routes>
        </main>
      </header>
    </div>
  );
};

const App: React.FC = () => {
  const authStore = createStore({
    authName:'_auth',
    authType:'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === "https:",
  });

  return (
    <AuthProvider store={authStore}>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;
