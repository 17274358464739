import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import ImageCarousel from './ImageCarousel';

const Home: React.FC = () => {
  const photos = [
    '20240829_162831.jpg',
    '20240829_162849.jpg',
    '20240829_162901.jpg',
    'PXL_20240505_230210203.jpg',
    'Studio_8995w.jpg',
    'Studio_9006-2.jpg',
    'Studio_9010-2.jpg',
    'Studio_9012-2.jpg',
    'Studio_9021-2.jpg',
    'Studio_9029-2.jpg',
    'Studio_9031-2.jpg',
    'Studio_9043-2.jpg',
    'Studio_9067-2.jpg',
    'Studio_9082-2.jpg',
    'Studio_9092-2.jpg',
    'Studio_9109-2.jpg',
    'Studio_9111-2.jpg',
    'Studio_9114-2.jpg',
    'Studio_9119w.jpg',
    'Studio_9128w.jpg'
  ];

  const startingIndex = photos.indexOf('Studio_9029-2.jpg');

  return (
    <div className="home-container">
      <div className="logo-container">
        <img src="/logo-wide.png" alt="South Denver Studio" className="studio-logo" />
      </div>

      <p>Photo studio available for monthly and hourly rent in the Denver Tech Center</p>
      <p>2 bedrooms • 2 other shooting rooms • kitchen • 2 baths • outdoor space</p>
      <p>
        Whether you are shooting boudoir, headshots, food, lifestyle, or other genres, this meticulously converted home boasts versatile
        environments to bring creative visions to life.
      </p>

      <ImageCarousel 
        images={photos} 
        startingIndex={startingIndex}
        autoPlayInterval={15000}
      />

      <div className="rates-container">
        <h2>Rental Rates</h2>
        <ul>
          <li>2h - $125</li>
          <li>4h - $225</li>
          <li>6h - $325</li>
          <li>Daily - $400</li>
        </ul>
        <p>Subscription discounts - available to members</p>
      </div>

      <div className="inquiries-link">
        <Link to="/inquiries">Contact Us</Link>
      </div>
    </div>
  );
};

export default Home;
