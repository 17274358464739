import React from 'react';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { Navigate, useLocation } from 'react-router-dom';

interface RequireAuthProps {
  children: React.ReactElement;
  loginPath: string;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children, loginPath }) => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  if (isAuthenticated) {
    return children;
  }

  // Redirect to login page if not authenticated
  return <Navigate to={loginPath} state={{ from: location }} replace />;
};

export default RequireAuth;