import React, { useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import './Inquiries.css';
import '../utilities.css';

interface FormData {
  name: string;
  email: string;
  phone: string;
  inquiry: string;
}

interface ValidationErrors {
  name: string;
  email: string;
  phone: string;
  inquiry: string;
}

const Inquiries: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    inquiry: '',
  });

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
    name: '',
    email: '',
    phone: '',
    inquiry: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const validateField = (fieldName: string, value: string) => {
    let errorMessage = '';
    switch (fieldName) {
      case 'name':
        errorMessage = value.trim() === '' ? 'Name is required' : '';
        break;
      case 'email':
        errorMessage = !validateEmail(value) ? 'Please enter a valid email address' : '';
        break;
      case 'phone':
        errorMessage = value.trim() !== '' && !validatePhone(value) ? 'Please enter a valid 10-digit phone number' : '';
        break;
      case 'inquiry':
        errorMessage = value.trim() === '' ? 'Inquiry is required' : '';
        break;
    }
    setValidationErrors(prev => ({ ...prev, [fieldName]: errorMessage }));
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  const validatePhone = (phone: string): boolean => {
    const digits = phone.replace(/\D/g, '');
    return digits.length === 10;
  };

  const isFormValid = (): boolean => {
    return formData.name.trim() !== '' && 
           formData.email.trim() !== '' && 
           formData.inquiry.trim() !== '' && 
           Object.values(validationErrors).every(error => error === '');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isFormValid()) {
      alert('Please correct the errors in the form.');
      return;
    }
    try {
      await axios.post('/api/inquiries', {
        ...formData,
        phone: formData.phone.replace(/\D/g, ''), // Send only digits
      });
      alert('Thank you for your inquiry! We will get back to you soon.');
      // Reset form after successful submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        inquiry: '',
      });
      // Navigate to home page after user confirms the alert
      navigate('/');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting the form. Please try again.');
    }
  };

  return (
    <div className="inquiries-container">
      <div className="sds-logo mb-4">
        <img src="/logo-wide.png" alt="South Denver Studio Logo" />
      </div>
      <h1 className="mb-4">Contact Us</h1>
      <div className="thank-you-message mb-4">
        <h2 className="mb-2">Thank You for Your Interest!</h2>
        <p>We appreciate your interest in our photo studio. To help us serve you better, please fill out the form below. We look forward to assisting you with your photography needs!</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name: *</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            maxLength={50}
          />
          {validationErrors.name && <p className="error-message">{validationErrors.name}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email: *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            maxLength={100}
          />
          {validationErrors.email && <p className="error-message">{validationErrors.email}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone:</label>
          <InputMask
            mask="(999) 999-9999"
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="(xxx) xxx-xxxx"
          />
          {validationErrors.phone && <p className="error-message">{validationErrors.phone}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="inquiry">Inquiry: *</label>
          <textarea
            id="inquiry"
            name="inquiry"
            value={formData.inquiry}
            onChange={handleChange}
            required
            maxLength={500}
            rows={4}
          />
          {validationErrors.inquiry && <p className="error-message">{validationErrors.inquiry}</p>}
        </div>
        <div className="flex-center">
          <button type="submit" disabled={!isFormValid()}>Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Inquiries;
