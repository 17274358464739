import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import InputMask from 'react-input-mask';
import { jwtDecode } from 'jwt-decode';
import './UserProfile.css';
import '../utilities.css';

interface UserProfileData {
  name: string;
  businessName: string;
  email: string;
  phone: string;
  pendingEmail?: string;
}

interface ValidationErrors {
  name: string;
  email: string;
  phone: string;
}

interface JwtPayload {
  exp: number;
  [key: string]: any;
}

const UserProfile: React.FC = () => {
  const [profile, setProfile] = useState<UserProfileData>({
    name: '',
    businessName: '',
    email: '',
    phone: '',
    pendingEmail: undefined
  });
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
    name: '',
    email: '',
    phone: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [showVerificationConfirmation, setShowVerificationConfirmation] = useState(false);


  const { token } = useParams<{ token?: string }>();
  const navigate = useNavigate();
  const authHeader = useAuthHeader();

  const isTokenValid = (token: string): boolean => {
    try {
      const decoded: JwtPayload = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (error) {
      return false;
    }
  };

  const fetchUserProfile = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const token = authHeader;
      if (!token) {
        throw new Error('No authentication token found');
      }

      if (!isTokenValid(token.split(' ')[1])) {
        throw new Error('Token is expired or invalid');
      }

      const response = await fetch('/api/auth/profile', {
        method: 'GET',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        if (response.status === 429) {
          setError('Too many requests. Please try again later.');
          return;
        }
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch user profile');
      }

      const data = await response.json();
      setProfile({
        name: data.name || '',
        businessName: data.businessName || '',
        email: data.email || '',
        phone: data.phone || '',
        pendingEmail: data.pendingEmail
      });
      localStorage.setItem('cachedUserProfile', JSON.stringify(data)); // Persist cache in localStorage
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Error fetching user profile');
    } finally {
      setIsLoading(false);
    }
  }, [authHeader]);

  const verifyEmail = useCallback(async (verificationToken: string): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/auth/verify-email/${verificationToken}`, {
        method: 'GET',
        credentials: 'include'
      });

      if (!response.ok) {
        if (response.status === 429) {
          setError('Too many requests. Please try again later.');
          return;
        }
        const errorData = await response.json();
        setError(errorData.error || 'Email verification failed.');
        return;
      }

      const data = await response.json();
      setError(data.message);

      if (authHeader) {
        await fetchUserProfile();
        setShowVerificationConfirmation(true);
      } else {
        navigate('/login', { replace: true });
      }
    } catch (error) {
      setError('An error occurred during email verification.');
    } finally {
      setIsLoading(false);
      if (token) {
        navigate('/profile', { replace: true });
      }
    }
  }, [navigate, fetchUserProfile, authHeader, token]);

  useEffect(() => {
    if (token) {
      verifyEmail(token);
    } else if (authHeader) {
      fetchUserProfile(); // Always fetch profile data if authenticated
    } else {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [token, verifyEmail, fetchUserProfile, authHeader, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfile(prev => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (fieldName: string, value: string) => {
    let errorMessage = '';
    switch (fieldName) {
      case 'name':
        errorMessage = value.trim() === '' ? 'Name is required' : '';
        break;
      case 'email':
        errorMessage = !validateEmail(value) ? 'Please enter a valid email address' : '';
        break;
      case 'phone':
        errorMessage = !validatePhone(value) ? 'Please enter a valid 10-digit phone number' : '';
        break;
    }
    setValidationErrors(prev => ({ ...prev, [fieldName]: errorMessage }));
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  const validatePhone = (phone: string): boolean => {
    const digits = phone.replace(/\D/g, '');
    return digits.length === 10;
  };

  const isFormValid = (): boolean => {
    return Object.values(validationErrors).every(error => error === '');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    validateField('name', profile.name);
    validateField('email', profile.email);
    validateField('phone', profile.phone);

    if (!isFormValid()) {
      setError('Please correct the errors in the form.');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const isEmailChanged = profile.email !== originalEmail;

      if (isEmailChanged) {
        const verificationResponse = await fetch('/api/auth/send-email-verification', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader || ''
          },
          body: JSON.stringify({ newEmail: profile.email }),
          credentials: 'include'
        });

        if (!verificationResponse.ok) {
          if (verificationResponse.status === 429) {
            setError('Too many requests. Please try again later.');
            setIsLoading(false);
            return;
          }
          const errorData = await verificationResponse.json();
          if (errorData.error === 'Email already in use') {
            setValidationErrors(prev => ({ ...prev, email: 'This email is already in use' }));
            setError('The email address is already in use. Please choose a different email.');
            setIsLoading(false);
            return;
          } else {
            throw new Error(errorData.error || 'Failed to send verification email');
          }
        }

        setNewEmail(profile.email);
        setShowEmailModal(true);
        setProfile(prev => ({ ...prev, pendingEmail: profile.email }));
      }

      const updateResponse = await fetch('/api/auth/profile', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authHeader || ''
        },
        body: JSON.stringify({
          name: profile.name,
          businessName: profile.businessName,
          email: profile.email,
          phone: profile.phone.replace(/\D/g, ''),
        }),
        credentials: 'include'
      });

      if (!updateResponse.ok) {
        if (updateResponse.status === 429) {
          setError('Too many requests. Please try again later.');
          setIsLoading(false);
          return;
        }
        const errorData = await updateResponse.json();
        throw new Error(errorData.error || 'Failed to update profile');
      }

      setIsEditing(false);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Error updating profile');
    } finally {
      setIsLoading(false);
    }
  };

  const formatPhoneForDisplay = (phone: string): string => {
    const cleaned = phone.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phone;
  };

  const EmailVerificationModal: React.FC<{ onClose: () => void }> = ({ onClose }) => (
    <div className="modal-overlay">
      <div className="modal">
        <h2 className="modal-title">Email Verification Required</h2>
        <p>We've sent a verification email to <strong>{newEmail}</strong>.</p>
        <p>Please check your email and click the verification link to complete the email change process.</p>
        <p>Your profile has been updated, but the new email will not be active until verified.</p>
        <div className="flex-center">
          <button onClick={() => {
            onClose();
            setProfile(prev => ({ ...prev, pendingEmail: newEmail }));
          }}>Close</button>
        </div>
      </div>
    </div>
  );

  const EmailVerificationConfirmationModal: React.FC<{ onClose: () => void }> = ({ onClose }) => (
    <div className="modal-overlay">
      <div className="modal">
        <h2 className="modal-title">Email Verification Successful</h2>
        <p>Your email address has been successfully verified and updated.</p>
        <p>Please use your new email address the next time you log in.</p>
        <div className="flex-center">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-profile">
      <div className="sds-logo">
        <img src="/logo-wide.png" alt="South Denver Studio Logo" />
      </div>
      <div className="profile-container">
        <h2>User Profile</h2>
        {error && <p className="error-message">{error}</p>}
        {profile.pendingEmail && (
          <p className="text-light mb-2">
            Email change to {profile.pendingEmail} is pending verification. Please check your email for a verification link.
          </p>
        )}
        {isEditing ? (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name: *</label>
              <input
                type="text"
                id="name"
                name="name"
                value={profile.name}
                onChange={handleInputChange}
                required
              />
              {validationErrors.name && <p className="error-message">{validationErrors.name}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="businessName">Business Name:</label>
              <input
                type="text"
                id="businessName"
                name="businessName"
                value={profile.businessName}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email: *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={profile.email}
                onChange={handleInputChange}
                required
              />
              {validationErrors.email && <p className="error-message">{validationErrors.email}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone: *</label>
              <InputMask
                mask="(999) 999-9999"
                type="tel"
                id="phone"
                name="phone"
                value={profile.phone}
                onChange={handleInputChange}
                placeholder="(xxx) xxx-xxxx"
                required
              />
              {validationErrors.phone && <p className="error-message">{validationErrors.phone}</p>}
            </div>
            <div className="flex-center mt-2">
              <button type="submit" disabled={isLoading || !isFormValid()}>
                {isLoading ? 'Saving...' : 'Save Changes'}
              </button>
              <button type="button" onClick={() => setIsEditing(false)} disabled={isLoading}>
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <div className="profile-info">
            <div>
              <label>Name</label>
              <span>{profile.name || 'Not provided'}</span>
            </div>
            <div>
              <label>Business Name</label>
              <span>{profile.businessName || 'Not provided'}</span>
            </div>
            <div>
              <label>Email</label>
              <span>{profile.email || 'Not provided'}</span>
            </div>
            <div>
              <label>Phone</label>
              <span>{formatPhoneForDisplay(profile.phone) || 'Not provided'}</span>
            </div>
            <button onClick={() => {
              setIsEditing(true);
              setOriginalEmail(profile.email);
            }}>Edit Profile</button>
          </div>
        )}
      </div>
      {showEmailModal && (
        <EmailVerificationModal onClose={() => setShowEmailModal(false)} />
      )}
      {showVerificationConfirmation && (
        <EmailVerificationConfirmationModal onClose={() => setShowVerificationConfirmation(false)} />
      )}
    </div>
  );
};

export default UserProfile;
