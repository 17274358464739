import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import useSignOut from 'react-auth-kit/hooks/useSignOut';

const EmailVerification: React.FC = () => {
  const [verificationStatus, setVerificationStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [message, setMessage] = useState<string>('');
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`/api/auth/verify-email/${token}`);
        setVerificationStatus('success');
        setMessage(response.data.message || 'Email verified successfully');
      } catch (error) {
        console.error('Email verification failed:', error);
        setVerificationStatus('error');
        setMessage((error as any).response?.data?.error || 'An error occurred during verification');
      }
    };

    if (token) {
      verifyEmail();
    } else {
      setVerificationStatus('error');
      setMessage('Invalid verification link');
    }
  }, [token]);

  const handleConfirm = () => {
    if (verificationStatus === 'success') {
      signOut();
    }
    navigate(isAuthenticated ? '/profile' : '/login');
  };

  return (
    <div className="email-verification">
      <h2>Email Verification</h2>
      {verificationStatus === 'loading' && <p>Verifying your email...</p>}
      {verificationStatus === 'success' && (
        <>
          <p>{message}</p>
          <p>Please log in again using your NEW email address.</p>
          <button onClick={handleConfirm}>Confirm</button>
        </>
      )}
      {verificationStatus === 'error' && (
        <>
          <p>The email change cannot be completed: {message}</p>
          <p>If you wish to restart the process, please update and save your email again at the User Profile page.</p>
          {!isAuthenticated && (
            <p>You can still log on using your OLD email.</p>
          )}
          <button onClick={handleConfirm}>Confirm</button>
        </>
      )}
    </div>
  );
};

export default EmailVerification;
