import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Booking.css';
import '../utilities.css';

const extractNameParts = (fullName: string): { firstName: string; lastName: string } => {
  const titles = ['mr', 'dr', 'ms', 'mrs', 'miss', 'phd', 'msw', 'dds', 'lpc', 'ii', 'iii', 'iv', 'jr', 'sr'];
  let name = fullName.trim();

  // Remove punctuation except dashes, commas, and semicolons
  name = name.replace(/[^\w\s-,;]/g, '');

  // Remove anything after a comma or semicolon
  name = name.split(',')[0].split(';')[0];

  const nameParts = name.split(' ');

  // Remove titles (case-insensitive)
  const filteredNameParts = nameParts.filter(part => !titles.some(title => title.toLowerCase() === part.toLowerCase()));

  const firstName = filteredNameParts.length > 0 ? filteredNameParts[0] : '';
  const lastName = filteredNameParts.length > 1 ? filteredNameParts.slice(-1)[0] : '';

  return { firstName, lastName };
};

const Booking: React.FC = () => {
  const location = useLocation();
  const [iframeUrl, setIframeUrl] = useState('');
  const [userProfile, setUserProfile] = useState<any>(null);

  useEffect(() => {
    const cachedProfile = localStorage.getItem('cachedUserProfile');
    if (cachedProfile) {
      setUserProfile(JSON.parse(cachedProfile));
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const date = searchParams.get('date');

    let url = 'https://app.acuityscheduling.com/schedule.php?owner=33382033&ref=embedded_csp';

    if (date) {
      url += `&date=${date}`;
    }

    // Add user profile data to the URL if available
    if (userProfile) {
      const { firstName, lastName } = extractNameParts(userProfile.name);
      const phone = userProfile.phone ? userProfile.phone.toString() : '';

      url += `&firstName=${encodeURIComponent(firstName)}`;
      url += `&lastName=${encodeURIComponent(lastName)}`;
      url += `&email=${encodeURIComponent(userProfile.email)}`;
      url += `&phone=${encodeURIComponent(phone)}`;
    }

    setIframeUrl(url);
  }, [location, userProfile]);

  return (
    <div className="booking-container dark-theme">
      <div className="sds-logo mb-4">
        <img src="/logo-wide.png" alt="South Denver Studio Logo" />
      </div>
      <div className="booking-iframe-container">
        {iframeUrl && (
          <iframe
            src={iframeUrl}
            title="Schedule Appointment"
            width="100%"
            height="938"
            frameBorder="0"
          />
        )}
        <div className="acuity-login-obscurer" />
        <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
      </div>
    </div>
  );
};

export default Booking;
