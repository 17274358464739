import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import './Login.css';
import '../utilities.css';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const navigate = useNavigate();
  const signIn = useSignIn();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
        credentials: 'include',
      });

      if (!response.ok) {
        if (response.status === 429) {
          throw new Error('Too many login attempts. Please try again later.');
        } else if (response.status === 400) {
          throw new Error('Incorrect email or password');
        } else {
          const errorText = await response.text();
          throw new Error(errorText);
        }
      }

      const data = await response.json();
      localStorage.setItem('cachedUserProfile', JSON.stringify(data.userProfile));

      if (signIn({
        auth: {
          token: data.token,
          type: "Bearer"
        },
        userState: { email: data.userProfile.email }
      })) {
        navigate('/booking');
      } else {
        throw new Error('Failed to set authentication');
      }
    } catch (err: any) {
      console.error('Login error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="sds-logo mb-4">
          <img src="/logo-wide.png" alt="South Denver Studio Logo" />
        </div>
        <h1 className="mb-4">Login</h1>
        {error && <p className="error-message mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isLoading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={isLoading}
            />
          </div>
          <div className="flex-center mt-2">
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </form>
        <div className="forgot-password mt-4">
          <button
            onClick={() => setIsForgotPasswordOpen(true)}
            disabled={isLoading}
          >
            Forgot Password?
          </button>
        </div>
        {isForgotPasswordOpen && (
          <ForgotPasswordDialog onClose={() => setIsForgotPasswordOpen(false)} />
        )}
      </div>
    </div>
  );
};

export default Login;
