import React, { useState, useEffect, useCallback } from 'react';
import './ImageCarousel.css';

interface ImageCarouselProps {
  images: string[];
  startingIndex?: number;
  autoPlayInterval?: number;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ 
  images, 
  startingIndex = 0,
  autoPlayInterval = 15000 
}) => {
  // State management
  const [currentIndex, setCurrentIndex] = useState(startingIndex);
  const [previousIndex, setPreviousIndex] = useState(startingIndex);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(startingIndex);

  // Constants
  const VISIBLE_THUMBNAILS = 7;
  const TRANSITION_DURATION = 450; // Updated to 450ms

  // Get thumbnail path helper
  const getThumbnailPath = (imagePath: string) => 
    `/images/generated/thumbnail_${imagePath.replace(/\.[^.]+$/, '.webp')}`;

  // Calculate visible thumbnail indices
  const getVisibleThumbnails = (centerIndex: number): number[] => {
    const indices: number[] = [];
    for (let i = -VISIBLE_THUMBNAILS; i <= VISIBLE_THUMBNAILS; i++) {
      let index = (centerIndex + i + images.length) % images.length;
      if (index < 0) index += images.length;
      indices.push(index);
    }
    return indices;
  };

  // Image transition handler
  const handleImageChange = useCallback((newIndex: number) => {
    if (isTransitioning) return;
    
    setActiveThumbnailIndex(-1);
    setIsTransitioning(true);
    setPreviousIndex(currentIndex);
    setCurrentIndex(newIndex);
    setActiveThumbnailIndex(newIndex);

    setTimeout(() => {
      setIsTransitioning(false);
    }, TRANSITION_DURATION);
  }, [currentIndex, isTransitioning]);

  // Navigation handlers
  const goToNext = useCallback(() => {
    handleImageChange((currentIndex + 1) % images.length);
  }, [currentIndex, images.length, handleImageChange]);

  const goToPrev = useCallback(() => {
    handleImageChange((currentIndex - 1 + images.length) % images.length);
  }, [currentIndex, images.length, handleImageChange]);

  const goToIndex = useCallback((index: number) => {
    if (index === currentIndex) return;
    handleImageChange(index);
  }, [currentIndex, handleImageChange]);

  // Auto-advance effect
  useEffect(() => {
    if (!autoPlayInterval) return;

    const timer = setInterval(() => {
      const nextIndex = (currentIndex + 1) % images.length;
      handleImageChange(nextIndex);
    }, autoPlayInterval);

    return () => clearInterval(timer);
  }, [currentIndex, autoPlayInterval, images.length, handleImageChange]);

  // Image preloading effect with staged loading
  useEffect(() => {
    let mounted = true;

    const preloadImage = (src: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = reject;
        img.src = src;
      });
    };

    const preloadImages = async () => {
      try {
        // Stage 1: Load current image and its thumbnail
        console.log('Stage 1: Loading current image and thumbnail');
        await Promise.all([
          preloadImage(`/images/${images[currentIndex]}`),
          preloadImage(getThumbnailPath(images[currentIndex]))
        ]);

        if (!mounted) return;
        setImagesLoaded(true);

        // Stage 2: Load all thumbnails
        console.log('Stage 2: Loading all thumbnails');
        const thumbnailPromises = images
          .filter((_, index) => index !== currentIndex) // Skip current thumbnail
          .map(image => preloadImage(getThumbnailPath(image)));
        
        await Promise.all(thumbnailPromises);
        if (!mounted) return;

        // Stage 3: Load remaining full-size images in background
        console.log('Stage 3: Loading remaining full-size images');
        images
          .filter((_, index) => index !== currentIndex) // Skip current image
          .forEach(image => {
            // Don't await these - let them load in background
            preloadImage(`/images/${image}`).catch(error => {
              console.warn(`Background loading failed for image: ${image}`, error);
            });
          });

      } catch (error) {
        console.error('Error in preloading sequence:', error);
        if (mounted) {
          setImagesLoaded(true); // Continue even if preloading fails
        }
      }
    };

    preloadImages();

    return () => {
      mounted = false;
    };
  }, []);

  if (!imagesLoaded) {
    return <div className="carousel-loading">Loading...</div>;
  }

  return (
    <div className="carousel-wrapper">
      {/* Main image display */}
      <div className="main-image-container">
        <button className="nav-arrow prev" onClick={goToPrev}>
          <span>&#10094;</span>
        </button>
        
        {/* Previous image (for fade transition) */}
        {isTransitioning && (
          <img
            src={`/images/${images[previousIndex]}`}
            alt={`Studio scene ${previousIndex + 1}`}
            className="main-image fade-out"
          />
        )}
        
        {/* Current image */}
        <img
          src={`/images/${images[currentIndex]}`}
          alt={`Studio scene ${currentIndex + 1}`}
          className={`main-image ${isTransitioning ? 'fade-in' : ''}`}
        />
        
        <button className="nav-arrow next" onClick={goToNext}>
          <span>&#10095;</span>
        </button>
      </div>

      {/* Thumbnails strip */}
      <div className="thumbnails-container">
        <div className="thumbnails-wrapper">
          {getVisibleThumbnails(currentIndex).map((index) => (
            <img
              key={`${index}-${activeThumbnailIndex}`}
              src={getThumbnailPath(images[index])}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail ${index === activeThumbnailIndex ? 'active' : ''}`}
              onClick={() => goToIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
